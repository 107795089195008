import * as Sentry from '@sentry/vue'
import { DefineComponent, createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import NProgress from 'nprogress'
import { router } from '@inertiajs/vue3'

NProgress.configure({
  showSpinner: false,
  trickle: true,
  trickleSpeed: 100,
})

createInertiaApp({
  progress: false,
  resolve: name =>
    resolvePageComponent(
      `./pages/${name}.vue`,
      import.meta.glob<boolean, string, DefineComponent>('./pages/**/*.vue'),
    ),
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) }).use(plugin)
    const dsn = import.meta.env.VITE_SENTRY_DSN

    Sentry.init({
      app,
      dsn: dsn === 'null' ? null : dsn,
      tracesSampleRate: 1.0,
    })

    app.mixin(
      Sentry.createTracingMixins({
        trackComponents: true,
        hooks: [],
        timeout: 1000,
      }),
    )

    app.mount(el)
  },
})

router.on('start', () => NProgress.start())
router.on('finish', () => NProgress.done())
